@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400&display=swap');
.index {
  background-color: #171623;
  max-height: 100vh;
}

hr {
  opacity: 0;
}

.sm-menu {
  display: none;
}

i {
  color: #09f3c0;
  size: 36px;
}

a:hover {
  text-decoration: none;
}

.flex {
  display: flex;
  flex-direction: column;
}

h3, span {
  font-size: 18px;
  font-family: "Poppins" sans-serif;
  font-weight: 600;
  color: #E8E8E8
}

p {
  font-family: 'Open Sans', sans-serif;
  color: #ffffff;
  letter-spacing: 1.5px;
}

.col-12 {
  padding: 0px 0px;
  width: 100%;
}

.home {
  background-color: inherit !important;
}

.description {
  text-align: center;
  padding-top: 30%
}

.description h3 {
  font-size: 24px;
  font-weight: 500;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.description p {
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 1.3px;
}

li {
  list-style: none;
  padding-left: 15px;
}

ul {
  display: inline-block;
  margin: 0px 10px 5px;
}

.nav-bar .nav-item {
  text-align: right;
}

.nav-bar .github {
  text-align: left;
}

nav ul {
  display: flex;
  justify-content: flex-end;
  align-items: baseline;
}

.lg-bar {
  font-family: sans-serif;
  width: 100%;
  background-color: #171623;
  max-height: 60px;
  z-index: 1000;
}

.lg-bar nav .brand {
  float: left;
}

.lg-bar nav .brand li a {
  color: #09f3c0;
}

.lg-bar .contact-btn:hover {
  border-style: none;
  border: none;
  color: #fff;
  background: transparent;
  background-color: #09f3c0;
  width: 100px;
}

.lg-bar .contact-btn {
  font-size: inherit;
  border-radius: 2px;
  border-style: none;
  border: none;
  color: #09f3c0;
  background: transparent;
  border: 2px solid #ffffff;
  width: 100px;
  height: 35px;
}

.lg-bar nav {
  float: right;
}

.lg-bar .logo {
  height: 100px;
  width: 100px;
}

.lg-bar .nav-bar .active-link {
  color: #fff;
}

.lg-bar a:after {
  display: block;
  background: none repeat scroll 0 0 transparent;
  bottom: 5px;
  content: "";
  height: 3px;
  position: absolute;
  background: #fff;
  transition: width 0.3s ease 0s, left 0.3s ease 0s;
  width: 0;
}

.lg-bar a:hover:after {
  text-decoration: none;
  width: 50px;
}

footer {
  margin-bottom: 0px;
  height: 95px;
  background-color: inherit !important;
}

footer ul {
  display: flex;
  align-items: baseline;
}

.footer .footer-links {
  display: flex;
  justify-content: center;
}

footer {
  position: relative;
  margin-top: 70px;
}

footer a {
  text-decoration: none;
  color: #09f3c0;
  font-size: 18px;
  font-weight: 500;
}

footer ul li {
  margin: 10px 15px;
}

@media screen and (max-width: 700px) {
  .lg-bar {
    display: none;
  }
  .description p {
    text-align: left;
    font-size: 16px;
  }
  .mobile-menu {
    position: fixed;
    right: 10px;
    width: 95%;
    background-color: #171623;
    z-index: 1000;
  }
  .mobile-menu .nav {
    padding-top: 5px;
  }
  .mobile-menu .nav .nav-item a {
    display: inline;
    color: #171717;
    font-size: 16px;
  }
  .mobile-menu .nav .nav-item {
    margin-top: 20px;
    margin-left: 20px;
  }
  .sm-menu {
    z-index: 1000;
    display: block;
  }
  .mobile-menu .nav-toggled {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
  }
  .menu-btn {
    float: right;
    cursor: pointer;
    display: block;
    position: relative;
    margin: 2px;
  }
  .mobile-menu::before {
    content: " ";
    display: table;
  }
  .nav {
    z-index: 99;
    display: block;
    background-color: #ffffff;
    width: 95%;
    height: 200px;
    right: 0;
    left: 0;
    float: right;
    margin: auto;
    top: 11px;
  }
  .nav-menu * {
    box-sizing: border-box;
  }
  span {
    font-size: 15px;
    font-family: "Poppins" sans-serif;
    font-weight: 600;
    color: #464646
  }
  .nav-bar {
    visibility: hidden;
  }
  .menu ul {
    display: flex;
    flex-direction: column;
  }
  .iot {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  hr {
    opacity: 100;
    width: 100%;
    margin-block: 0;
    background-color: #333152;
  }
  .home {
    margin-top: 30px;
  }
  .about {
    text-align: center;
    flex-wrap: wrap;
  }
  .description-bottom {
    text-align: center;
  }
  .description {
    padding: 0px 10px;
  }
  .about .headshot {
    padding: 10px;
  }
  .nav-bar {
    text-align: center;
    padding: 5px;
  }
}

.about {
  display: flex;
  justify-content: space-evenly;
}

.headshot {
  margin: 10px 10px 5px;
  padding: 7px;
  box-shadow: 0px 1px 2px #09f3c0;
  width: 200px;
  height: 200px;
  min-width: 180px;
}

.headshot .header {
  text-align: center;
}

.headshot .header img {
  border-radius: 50%;
  height: 100px;
  width: 100px;
}

.about .headshot span {
  font-size: 16px;
  font-family: "Poppins" sans-serif;
  font-weight: 500;
  color: #E8E8E8
}

.avatar {
  border-radius: 2px;
  height: 100px;
  width: 100px;
}

nav {
  width: 100%;
  height: 60px;
  position: fixed;
  border-bottom: 1px solid #171717;
  background-color: inherit !important;
}

nav li a {
  font-weight: 400;
  font-size: 20px;
  color: #09f3c0;
}

.nav-bar {
  padding-top: 15px;
  margin-right: 50px
}

.unavailable {
  height: 100vh;
  background-color: #171623;
}

.unavailable .err-code h3 {
  color: #ffffff;
  font-weight: 200;
  font-size: 36px;
  font-family: monospace;
}

.unavailable span {
  color: #ffffff;
  font-weight: 700;
  font-size: 18px;
}

.unavailable .err-code {
  text-align: center;
  margin-top: 40vh;
}